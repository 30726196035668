import logo from './monogram.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="App-Coming-Soon">
       <div>
           <img src={logo} className="App-logo" alt="logo" />
           <h1>IMPROBABLE SEAMS</h1>
           <p>AW23 Collection coming soon!</p>
           <div className="App-Social">
               <a className="Social-Link" target="_blank" href="https://www.instagram.com/improbable.seams/">
                   <img
                       className="Social-Image"
                   src="instagram.svg"
                   alt="example"
               />
               </a>
               <a className="Social-Link" target="_blank" href="https://www.facebook.com/profile.php?id=61552300201500">
                   <img
                       className="Social-Image"
                       src="facebook.svg"
                       alt="example"
                   />
               </a>
           </div>
       </div>
      </div>
    </div>
  );
}

export default App;
